export const environment = {
  config: false,
  baseUrlAdminApi: 'https://vmomlt-aps-demo-we-apivnext-frontend-01.azurewebsites.net/',
  baseUrlPowerBIApi: 'vmomlt-aps-demo-we-apivnext-powerbi-01.azurewebsites.net',
  release: 'hope',
  production: false,
  buildNumber: '20241205.1',
  serviceWorker: false,
  googleMapsKey: 'AIzaSyAurjgjxJ_0e1gPw-9XIpa0ZJXof8cANiE',
  appinsight_instrumentationkey: '55bf795f-1f0d-4d9f-839d-afcec70205b9',
  bikeImageFallback: '/assets/map/img/libera_vaimoo.png'
};
